import { Connection, PublicKey } from '@solana/web3.js';

import * as anchor from '@project-serum/anchor';
import { signAndSendTransaction } from "@/libs/wallet.js";

import { establishConnection, findAssociatedTokenAddress } from "@/libs/solanaConnection";

import { TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";

import _idl from "@/IDL.json";

const idl = _idl;

// Address of the deployed program.
const program_id = new PublicKey('eH2n6S4Wq2FTSiLkfocikfqjrg8QAzLP5bAXCr3qLZi');

let connection: Connection;

const VAULT_ACCOUNT_PREFIX = "vault_wltoken_focikfqjrg";
const VAULT_AUTHORITY_PREFIX = "vault_auth_wltoken_focikfqjrg";

const VAULT_ACCOUNT_PREFIX_TOKEN = "aaaaaaaaaaaaaa";
const VAULT_AUTHORITY_PREFIX_TOKEN = "aaaaaaaaaaaa";

var options, provider, program;

async function init_program(owner) {
	
	connection = await establishConnection();
	
	options = anchor.Provider.defaultOptions();
	provider = new anchor.Provider(connection, owner, options);

	anchor.setProvider(provider);
	program = new anchor.Program(idl, program_id, provider);
}


export async function send_on_a_trip(owner, wallet_address, seamt_chosen, aquapipe_ata, seamt_ata) {
	
	init_program(owner);
	
	// DAO WALLET
	// 46vVXCixkSZLe3M9FMe8Uxpui5CaegNW41n2drxv4PqH

	
	var TODO_seamt_mint = seamt_chosen == 'token' ? 'DiqnnLzQUqoDpxqpazcyN2uTTS1fspjQfb3sWPAmB1gj' : seamt_chosen;
	var TODO_aquapipe_owner = aquapipe_ata;
	var TODO_seamt_owner = await findAssociatedTokenAddress(wallet_address, TODO_seamt_mint);
	
	// mint address of seamt
	var seamt_mint = new PublicKey(TODO_seamt_mint);
	
	// ATA for aquapipe for owner
	var ata_aquapipe_owner = new PublicKey(TODO_aquapipe_owner);
	
	// ATA for sea mt for owner
	var ata_seamt_owner = new PublicKey(TODO_seamt_owner);
	
	// mint address of the WL token
	var wltoken_mint = new PublicKey('Hey31eCG5ZKSdgM2nFSdbvv5CNLYSWCBjdQVfvJairp1');
	
	// DAO wallet (burn wallet)
	var burn_wallet = {publicKey: new PublicKey('46vVXCixkSZLe3M9FMe8Uxpui5CaegNW41n2drxv4PqH')};
	
	// ATA for aquapipe
	var ata_aquapipe_program = new PublicKey('4fpnF1WEK5UgWo815GtjU3qUffmWnvqkGdk3gfP6Q3Pq');
	
	// ATA for seamt
	var [instruction_ata_seamt_program, ata_seamt_program] = await prepareAta(seamt_mint, burn_wallet, owner);
	
	// ATA for WL token for owner
	var [instruction_ata_wl_token_owner, ata_wl_token_owner] = await prepareAta(wltoken_mint, owner);
	
	// Vault account for WL token
	const [vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX)],
		program_id
	);

	const [vault_authority_pda, _vault_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX)],
		program_id
	);
	
	// console.log('owner', owner.publicKey.toString());
	// console.log('tokenAccountAquapipeProgram', ata_aquapipe_program.toString());
	// console.log('tokenAccountAquapipeOwner', ata_aquapipe_owner.toString());
	// console.log('tokenAccountSeamtProgram', ata_seamt_program.toString());
	// console.log('tokenAccountSeamtOwner', ata_seamt_owner.toString());
	// console.log('tokenAccountWlTokenOwner', ata_wl_token_owner.toString());
	// console.log('vaultAccount', vault_account_pda.toString());
	// console.log('vaultAuthority', vault_authority_pda.toString());
	
	// get instruction for transfers
	const instruction = await program.instruction.initialize(
		{
		accounts: {
			owner: owner.publicKey,
			
			tokenAccountAquapipeProgram: ata_aquapipe_program,
			tokenAccountAquapipeOwner: ata_aquapipe_owner,
			
			tokenAccountSeamtProgram: ata_seamt_program,
			tokenAccountSeamtOwner: ata_seamt_owner,
			
			tokenAccountWlTokenOwner: ata_wl_token_owner,
			
			vaultAccount: vault_account_pda,
			vaultAuthority: vault_authority_pda,
			
			systemProgram: anchor.web3.SystemProgram.programId,
			rent: anchor.web3.SYSVAR_RENT_PUBKEY,
			tokenProgram: TOKEN_PROGRAM_ID,
		},
		// instructions: [],
		signers: [],
	});
	
	var transaction = new anchor.web3.Transaction();
	
	if(instruction_ata_wl_token_owner !== null)
		transaction.add(instruction_ata_wl_token_owner);
	
	if(instruction_ata_seamt_program !== null)
		transaction.add(instruction_ata_seamt_program);
	
	transaction.add(instruction);
	
	transaction.feePayer = new PublicKey(owner.publicKey);
	transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
	
	var signature = await signAndSendTransaction(owner, connection, transaction);
	
	return signature;
	
	
}

import { Keypair, Transaction, SystemProgram, } from "@solana/web3.js";
import { MintLayout } from "@solana/spl-token";
import { createCreateMetadataAccountV3Instruction } from "@metaplex-foundation/mpl-token-metadata";
import axios from "axios";
const BACKOFFICE_URL = "https://backend.coraltribe.io";

export async function mintOneTripMemory(payer) {

	connection = await establishConnection();

	var mint = await Keypair.generate();
	let transaction = new Transaction();

	let tripMemoryData = await axios.get(BACKOFFICE_URL+'/cct/trip/create_new_nft/'+mint.publicKey.toString());

	const tripMemoryId = tripMemoryData.data.id - 1;
	
	const rentExemptionAmount =
		await connection.getMinimumBalanceForRentExemption(MintLayout.span);

		
		const createAccountInstruction = SystemProgram.createAccount({
			fromPubkey: payer.publicKey,
			newAccountPubkey: mint.publicKey,
			lamports: rentExemptionAmount,
			space: MintLayout.span,
			programId: TOKEN_PROGRAM_ID,
		});
		
	const initMintInstruction = Token.createInitMintInstruction(
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		0,
		payer.publicKey,
		payer.publicKey,
	);

	let [createAtaInstruction, associatedDestinationTokenAddr] = await prepareAta(mint.publicKey, payer);

	const mintToInstruction = Token.createMintToInstruction(
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		associatedDestinationTokenAddr,
		payer.publicKey,
		[],
		1,
	);
	
	const [pdaMetadata, bum] = await PublicKey.findProgramAddress(
		[
		Buffer.from("metadata"),
		new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s").toBuffer(),
		mint.publicKey.toBuffer(),
		],
		new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")
	);
	
	var accounts = {
		metadata: pdaMetadata, // OK
		mint: mint.publicKey, // OK
		mintAuthority: payer.publicKey,
		payer: payer.publicKey,
		updateAuthority: payer.publicKey,
	};

	let metadata;

	metadata = {
		createMetadataAccountArgsV3: {
		data: {
			name: 'Trip Memory',
			symbol: 'TM',
			description: 'A psychedelic memory of ancient landscapes.',
			uri: 'https://seamt-devnet.s3.amazonaws.com/trip-outcome/'+tripMemoryId+'.json',
			sellerFeeBasisPoints: 750,
			creators: [
				{
					address: new PublicKey(
						"8tkcBvCPPTt7a6YLYizkkFNwMTdDBd4QvwPpghxFJgzk"
					),
					share: 0,
				},
				{
					address: new PublicKey(
						"HrgEQixsf7Hpw93ywFzUyXvfAaSUEV6KBRYeZPLMdi7R"
					),
					share: 50,
				},
				{
					address: new PublicKey(
						"9Gxo7xf9zvvSRqEmy7PxnEGVtGy95mYmBVj2ughbdnvs"
					),
					share: 20,
				},
				{
					address: new PublicKey(
						"AAeSe8yyCfDcG5fUjbTJjL21BA7xA7kVQYaUGWe8aMEg"
					),
					share: 30,
				},
			],
			collection: {
				key: new PublicKey(
					"9jxCbVrDY9S2TUNZKteF42gC6xumdVK9znMjvvoGTpqk"
				),
				verified: false, 
				name: "Trip Memory"
			},
			uses: null,
		},
		isMutable: true,
		collectionDetails: { __kind: "V1" },
		},
	};

	var metadataInstruction = await createCreateMetadataAccountV3Instruction(
		accounts,
		metadata
	);

	// transfert the token
	const wlTokenAddress = new PublicKey('Hey31eCG5ZKSdgM2nFSdbvv5CNLYSWCBjdQVfvJairp1');

	var tokenAccount = await findAssociatedTokenAddress(
		payer.publicKey,
		wlTokenAddress
	);

	var tokenAccountBurnWallet = new PublicKey('4VUS8zV8gh16jhMspU2FvcDmEJZenqjtmLuJPwchcLz7');
	
	let transferInstruction = await Token.createTransferInstruction(
		TOKEN_PROGRAM_ID,
        tokenAccount,
        tokenAccountBurnWallet,
        payer.publicKey,
		[],
        10
    )


	transaction.add(createAccountInstruction);
	transaction.add(initMintInstruction);
	transaction.add(createAtaInstruction);
	transaction.add(mintToInstruction);
	transaction.add(metadataInstruction);
	transaction.add(transferInstruction);

	transaction.feePayer = payer.publicKey;
	transaction.recentBlockhash = (
		await connection.getRecentBlockhash()
	).blockhash;

	transaction.sign(mint);

	var signature = await signAndSendTransaction(payer, connection, transaction);
	
	return [signature, mint];
}

export async function prepareAta(nftMint, payer, otherpayer = null) {
	
	// console.log("prepareAta, nftMint", nftMint);
	
	nftMint = new PublicKey(nftMint);
	
	// we create the tokenAccount
	var myToken = new Token(
		connection,
		nftMint,
		TOKEN_PROGRAM_ID,
		payer.publicKey
	);
	
	var payerForTransaction = payer;
	
	if(otherpayer !== null)
		payerForTransaction = otherpayer;
	
	const associatedDestinationTokenAddr = await Token.getAssociatedTokenAddress(
		myToken.associatedProgramId,
		myToken.programId,
		nftMint,
		payer.publicKey,  // to
		true
	);
	
	// check if it exists
	var receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr);
	
	var instruction = null;
	
	if (receiverAccount === null) {
		
		// console.log('payerForTransaction', payerForTransaction.publicKey);
		// console.log('payerForTransaction', payerForTransaction.publicKey.toString());
		// console.log('nftMint', nftMint.toString());
		// console.log('payer.publicKey', payer.publicKey.toString());

		instruction = Token.createAssociatedTokenAccountInstruction(
			myToken.associatedProgramId,
			myToken.programId,
			nftMint,
			associatedDestinationTokenAddr,
			payer.publicKey, // to
			payerForTransaction.publicKey // signer
		)
	}
	
	return [instruction, associatedDestinationTokenAddr];
}


