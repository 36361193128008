import $ from 'jquery'

export function cct_alert(message) {
  $('#cct_alert_container p').html(message)
  $('#cct_alert').addClass('active')

  setTimeout(function () {
    $('#cct_alert').removeClass('active')
    $('#cct_alert_container p').html('')
  }, 3000)
}
