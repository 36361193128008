<template>
  <span>
    <div id="mobile-view">
      <p>
        THE TRIP WAS DESIGNED FOR DESKTOP USAGE TO PROVIDE THE BEST USER
        EXPERIENCE.
      </p>
      <p>PLEASE SWITCH DEVICES TO EMBARK ON YOUR TRIP</p>
    </div>

    <div id="desktop">
      <!-- mint -->
      <div id="modal" v-show="mint === true && wl_tokens > 0">
        <div>
          <span class="title">Claim Trip Memory</span>
          You are eligible to claim a Trip Memory. Press here to either Ascend
          or Awaken...<br /><br />

          <span
            class="mint_button"
            v-if="mint_loading === false"
            @click="mint_one_token()"
            >Claim 1 trip memory</span
          >
          <span class="mint_button inactive" v-if="mint_loading === true"
            >Please wait...</span
          >

          <br /><span class="no_thank_you" @click="mint = false"
            >No thank you</span
          >
        </div>
      </div>

      <!-- loading -->
      <div id="loading" v-show="loading === true">
        <span class="title" v-if="loading_text !== null">{{
          loading_text
        }}</span>
        <span class="title" v-if="loading_text === null">Please wait...</span>

        <!-- <img src="assets/images/loading.gif" /> -->

        <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
          <polygon
            fill="none"
            stroke="#333333"
            stroke-width="1"
            points="16,0 32,32 0,32"
          ></polygon>
        </svg>
      </div>

      <!-- BG Banner -->
      <video autoplay muted loop class="banner" style="object-fit: cover">
        <source src="assets/img/aquapipe.mp4" type="video/mp4" />
      </video>

      <!-- BG Video -->
      <div id="video">
        <video src="assets/video/vid2.mp4"></video>
        <div id="background-outcome">
          <span></span>
          <span></span>

          <span
            class="outcome-title"
            style="margin-bottom: -60px"
            v-if="outcome_img !== null"
            >{{ outcome_title }}</span
          >
          <span
            class="outcome-title"
            style="margin-bottom: 40px"
            v-if="outcome_img === null"
            >{{ outcome_title }}</span
          >

          <img :src="'assets/img/' + outcome_img" v-if="outcome_img !== null" />
          <span class="outcome-description" style="margin-top: -60px">{{
            outcome_description
          }}</span>
          <div>
            <div
              class="button"
              style="width: 200px; height: 35px"
              @click="return_to_trip"
            >
              <div class="button-left-border"></div>
              <div class="button-right-border"></div>
              <div class="button-content">
                <span>RETURN TO TRIP</span>
              </div>
            </div>
            <a
              class="button"
              style="width: 200px; height: 35px"
              target="_blank"
              href="https://staking.coraltribe.io"
              v-if="
                outcome_img != 'outcome_landscape.png' && outcome_img !== null
              "
            >
              <div class="button-left-border"></div>
              <div class="button-right-border"></div>
              <div class="button-content">
                <span>APPLY TO A CORAL</span>
              </div>
            </a>
          </div>
          <span></span>
          <span></span>
        </div>
      </div>

      <!-- Buttons -->
      <div class="row top-buttons">
        <div class="container">
          <div class="col s12 m12 l6">
            <a
              href="javascript:void(0)"
              class="top-btn btn1 hvr-grow"
              id="scroll-to-bottom"
              @click="scroll_to_bottom()"
            >
              <img src="assets/img/btn1.svg" alt="EMBARK ON A ~TRIP~" />
            </a>
          </div>
          <div class="col s12 m12 l6">
            <a
              href="https://bit.ly/Sea-MT-Guide"
              class="top-btn btn2 hvr-grow"
              target="_blank"
            >
              <img src="assets/img/btn2.svg" alt="VIEW FULL MECHANICS" />
            </a>
          </div>
        </div>
      </div>

      <!-- connect wallet -->
      <div id="connect_wallet" v-show="wallet_address === null">
        <span class="title">Connect your wallet</span>

        <div class="wallets">
          <div @click="connect_wallet('phantom')">
            <span class="button-img cct_wallet_img"
              ><img src="/assets/images/wallet/phantom.jpg"
            /></span>
            <span>Phantom</span>
          </div>
          <div @click="connect_wallet('solflare')">
            <span class="button-img cct_wallet_img"
              ><img src="/assets/images/wallet/solflare.svg"
            /></span>
            <span>Solflare</span>
          </div>
          <div @click="connect_wallet('sollet')">
            <span class="button-img cct_wallet_img"
              ><img src="/assets/images/wallet/sollet.jpg"
            /></span>
            <span>Sollet</span>
          </div>
          <div @click="connect_wallet('slope')">
            <span class="button-img cct_wallet_img"
              ><img src="/assets/images/wallet/slope.png"
            /></span>
            <span>Slope</span>
          </div>
        </div>
      </div>

      <!-- Parallax Effect -->
      <div class="parallax">
        <div class="row" data-paroller-factor>
          <div class="col s12 m12 l12">
            <img
              src="assets/img/img1.png"
              alt="img"
              class="img-1"
              data-paroller-factor
            />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6 offset-m6 offset-l6">
            <img src="assets/img/n1.png" alt="img" data-paroller-factor />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6">
            <img
              src="assets/img/img2.png"
              alt="img"
              class="move-up"
              data-paroller-factor
            />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6 offset-m6 offset-l6">
            <img
              src="assets/img/img3.png"
              alt="img"
              class="move-up"
              data-paroller-factor
            />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6">
            <img
              src="assets/img/n2.png"
              alt="img"
              class="left-img"
              data-paroller-factor
            />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6 offset-m6 offset-l6">
            <img
              src="assets/img/n3.png"
              alt="img"
              class="right-img move-up"
              data-paroller-factor
            />
          </div>
        </div>

        <div class="row" data-paroller-factor>
          <div class="col s12 m6 l6">
            <img src="assets/img/img4.png" alt="img" data-paroller-factor />
          </div>
        </div>
      </div>

      <div class="row table-img">
        <div class="container">
          <div class="col s12 m12 l12 center-align table-section">
            <img src="assets/img/Table.png" alt="table" />
            <a
              href="https://bit.ly/Sea-MT-Guide"
              class="table-btn hvr-grow"
              target="_blank"
            >
              <img src="assets/img/btn2.svg" alt="button" />
            </a>
          </div>
        </div>
      </div>

      <div class="row steps">
        <div class="container">
          <div class="col s12 m4 l4">
            <div class="text">
              <h3>Step 1</h3>
              <p>Choose a Sea-MT tab to channel</p>
            </div>
            <div class="box">
              <img
                src="assets/img/b2.png"
                alt="img"
                v-if="seamt_selected === false"
              />
              <img
                :src="'assets/img/' + seamt_image"
                alt="img"
                v-if="seamt_selected !== false"
              />
            </div>
            <div
              class="align-center"
              v-if="wallet_address === false"
              @click="wallet_address = null"
            >
              <!-- <img src="assets/img/connect-btn.svg" alt="button" class="btn-3 hvr-grow"> -->

              <div class="btn-center">
                <div class="button" style="width: 100px; height: 29px">
                  <div class="button-left-border"></div>
                  <div class="button-right-border"></div>
                  <div class="button-content">
                    <span>CONNECT</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="align-center"
              v-if="wallet_address !== false && wallet_address !== null"
              @click="chose_seamt()"
            >
              <!-- <img src="assets/img/trip-btn.svg" alt="button" class="btn-3 hvr-grow"> -->
              <div class="btn-center">
                <div class="button" style="width: 100px; height: 29px">
                  <div class="button-left-border"></div>
                  <div class="button-right-border"></div>
                  <div class="button-content">
                    <span>SELECT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m4 l4">
            <div class="text">
              <h3>Step 2</h3>
              <p>Prepare your Aqua Pipe for ignition</p>
            </div>
            <div class="box">
              <img
                src="assets/img/b3.png"
                alt="img"
                v-if="aquapipe_selected === false"
              />
              <img
                src="assets/img/aquapipe_frame.png"
                alt="img"
                v-if="aquapipe_selected !== false"
              />
            </div>
            <div
              class="align-center"
              v-if="wallet_address === false"
              @click="wallet_address = null"
            >
              <div class="btn-center">
                <div class="button" style="width: 100px; height: 29px">
                  <div class="button-left-border"></div>
                  <div class="button-right-border"></div>
                  <div class="button-content">
                    <span>CONNECT</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="align-center"
              v-if="wallet_address !== false && wallet_address !== null"
              @click="chose_aquapipe()"
            >
              <div class="btn-center">
                <div class="button" style="width: 100px; height: 29px">
                  <div class="button-left-border"></div>
                  <div class="button-right-border"></div>
                  <div class="button-content">
                    <span>SELECT</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m4 l4">
            <div class="text">
              <h3>Step 3</h3>
              <p>Embark on a ~Trip~ to Ascend or Awaken</p>
            </div>
            <div class="box">
              <img src="assets/img/b1.png" alt="img" />
            </div>
            <div
              class="align-center"
              id="trip-btn"
              style="margin-top: -50%; margin-left: -60px"
              v-if="
                true || (aquapipe_selected === true && seamt_selected === true)
              "
              @click="send_on_a_trip()"
            >
              <a href="javascript:void(0);">
                <div class="btn-center" style="width: 100%">
                  <div class="button" style="width: 100px; height: 29px">
                    <div class="button-left-border"></div>
                    <div class="button-right-border"></div>
                    <div class="button-content">
                      <span>TRIP</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import {
  establishConnection,
  getNftOwned,
  getTokenAccountForMintAddress,
  getTokenAccountForTokenAndOwner,
  findAssociatedTokenAddress,
  getTokenBalanceForTokenAndOwner,
  getNftMetadata,
} from "@/libs/solanaConnection.js";
import {
  send_on_a_trip,
  prepareAta,
  mintOneTripMemory,
} from "@/libs/rustProgram.js";

import {
  connexion_with_sollet,
  connexion_with_slope,
  getWalletAddress,
  getSolanaObject,
} from "@/libs/wallet";

import { mintOneToken, getCandyMachineState } from "@/libs/candyMachine";

import { cct_alert } from "@/libs/CCT_alert.js";

import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

import Vue from "vue";

import $ from "jquery";

import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import axios from "axios";

let config_axios = {
  headers: {
    "Content-Type": "application/json;",
  },
};

const BACKOFFICE_URL = "https://backend.coraltribe.io";

export default {
  name: "Stacking",
  components: {},

  data: function () {
    return {
      tokens_aquapipe: 0,
      tokens_seamt: 0,
      aquapipe_selected: false,
      seamt_selected: false,
      wallet_address: null,
      seamt_chosen: false,
      aquapipe_ata: false,
      seamt_ata: false,
      mint_loading: false,
      loading: false,
      seamt_image: "seamt_frame_threatened.png",
      mint: true,
      wl_tokens: 0,
      all_seamt_mints: [],
      nfts_owned: [],
      seamt_nfts: [],
      outcome_img: "",
      outcome_title: "",
      outcome_description: "",
      loading_text: "Please wait...",
    };
  },

  computed: {},
  methods: {
    connect_wallet: async function (wallet_name) {
      var component_vue = this;

      if (wallet_name == "phantom" && getProviderPhantom() == undefined) return;
      else if (wallet_name == "solflare" && getProviderSolflare() == undefined)
        return;
      else if (wallet_name == "sollet") {
        var sollet_wallet = await connexion_with_sollet();

        if (sollet_wallet != null && sollet_wallet.connected === false) return;

        return component_vue.connexion_wallet_connect(
          sollet_wallet.publicKey.toString()
        );
      } else if (wallet_name == "slope") {
        var slope_wallet = await connexion_with_slope();

        if (slope_wallet === false) {
          window.open("https://slope.finance", "_blank");
          return;
        } else {
          return component_vue.connexion_wallet_connect(
            slope_wallet.publicKey.toString()
          );
        }
      }

      var provider_name = "";

      if (wallet_name == "phantom") provider_name = window.solana;
      else if (wallet_name == "solflare") provider_name = window.solflare;

      provider_name.connect();

      provider_name.on("connect", () => {
        var wallet_address = "";

        if (wallet_name == "phantom")
          wallet_address = provider_name.publicKey.toBase58();
        else if (wallet_name == "solflare" || wallet_name == "sollet")
          wallet_address = provider_name.publicKey.toString();

        return component_vue.connexion_wallet_connect(wallet_address);
      });
    },

    check_if_aquapipe: async function (wallet_address) {
      const token_address = "FjBddvSNjJZNzzZA1Lqq8QNCVBcmREyFNTriY6ZmC8ip";

      // var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
      var tokenAccount = await findAssociatedTokenAddress(
        wallet_address,
        token_address
      );

      this.aquapipe_ata = tokenAccount;

      var balance = await getTokenBalanceForTokenAndOwner(
        token_address,
        tokenAccount
      );

      this.tokens_aquapipe = balance;
    },

    check_if_seamt_token: async function (wallet_address) {
      const token_address = "DiqnnLzQUqoDpxqpazcyN2uTTS1fspjQfb3sWPAmB1gj";

      // var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
      var tokenAccount = await findAssociatedTokenAddress(
        wallet_address,
        token_address
      );

      this.seamt_ata = tokenAccount;

      var balance = await getTokenBalanceForTokenAndOwner(
        token_address,
        tokenAccount
      );

      this.tokens_seamt = balance;
    },

    check_if_seamt: async function (wallet_address) {
      this.nfts_owned = await getNftOwned(this.wallet_address);

      this.seamt_nfts = [];

      var $this = this;

      this.nfts_owned.forEach(function (nft) {
        if ($this.all_seamt_mints[nft] != undefined) {
          $this.seamt_nfts.push($this.all_seamt_mints[nft]);
        }
      });
    },

    chose_aquapipe: function () {
      if (this.tokens_aquapipe >= 1) {
        this.aquapipe_selected = true;
      } else {
        cct_alert("No Aquapipe found in your wallet !");
      }
    },

    chose_seamt: async function () {
      await this.check_if_seamt();

      // sea mt nft
      if (this.seamt_nfts.length >= 1) {
        this.seamt_selected = true;

        for (var seamt_mint of this.seamt_nfts) {
          this.seamt_chosen = seamt_mint;

          return false;
        }
      }

      // sea mt token
      if (this.tokens_seamt >= 1) {
        this.seamt_selected = true;
        this.seamt_chosen = "token";

        return false;
      }

      cct_alert("No Sea-MT found in your wallet !");
    },

    play_video: async function () {
      $("#background-outcome").hide();
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        100
      );
      $(".banner, .top-buttons").hide();
      $("#video").show();
      $("video")[1].play();

      var $this = this;

      setTimeout(function () {
        // $("#background-outcome").fadeIn(1000);
        // $("#video video").hide();

        $this.loading = true;
        $this.loading_text =
          "Shamans are preparing for ritual, do not refresh or close page";
      }, 16000);
    },

    return_to_trip: async function () {
      $("#video").hide();
      $("#background-outcome").hide();
      $("#video video").show();
      $(".banner, .top-buttons").show();

      this.seamt_selected = false;
      this.aquapipe_selected = false;
    },

    wait_untill_account_is_fetchable: async function (account_address) {
      let connection = await establishConnection();

      account_address = new PublicKey(account_address);

      var account = await connection.getAccountInfo(account_address);

      if (account === null) {
        await new Promise((resolve) => setTimeout(resolve, 10000));

        return await this.wait_untill_account_is_fetchable(account_address);
      } else {
        return true;
      }
    },

    mint_one_token: async function () {
      this.mint_loading = true;

      this.loading = true;

      this.loading_text = "Embarking on ~Trip~, do not refresh or close page";

      let connection = await establishConnection();

      this.mint_loading = false;
      this.mint = false;

      // then we launch the mint
      /*
      var candy_machine_pubkey = "BdqVt9hQRpfm6qWiDF6792kSzjwE7GuPSbLk1Sv6gpAe";

      var [signature2, mint] = await mintOneToken(
        getSolanaObject(),
        candy_machine_pubkey,
        1
      );
      */
      var [signature2, mint] = await mintOneTripMemory(getSolanaObject());

      this.loading = false;

      if (signature2 === false) {
        cct_alert("Transaction failed, please try again");
        return;
      }

      this.play_video();

      await connection.confirmTransaction(signature2, "finalized");

      var nft_mint = new PublicKey(mint.publicKey.toString());

      var data_save_mint = {
        mint: mint.publicKey.toString(),
      };

      await axios.post(BACKOFFICE_URL + "/cct/trip/save_mint", data_save_mint);

      const [pda_metadata, bum] = await PublicKey.findProgramAddress(
        [
          Buffer.from("metadata"),
          new PublicKey(
            "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
          ).toBuffer(),
          nft_mint.toBuffer(),
        ],
        new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s")
      );

      // console.log('pda_metadata', pda_metadata.toString());
      /*
      await this.wait_untill_account_is_fetchable(pda_metadata);

      var mint_metadata;

      try {
        mint_metadata = await Metadata.fromAccountAddress(
          connection,
          pda_metadata
        );
      } catch (e) {
        // no metadata account

        $("#background-outcome").fadeIn(1000);
        $("#video video").hide();

        this.loading = false;

        this.outcome_title = "Trip outcome minted successfully !";
        this.outcome_description =
          "The outcome will be revealed in your wallet in the next minutes";
        this.outcome_img = null;

        cct_alert("Trip Memory minted successfully !");

        return;
      }

      // console.log('mint_metadata', mint_metadata);

      var uri = mint_metadata.data.uri.replaceAll("\u0000", "");
      */
      var data = {
        // uri: uri,
        mint: mint.publicKey.toString(),
      };

      var result = await axios.post(
        BACKOFFICE_URL + "/cct/trip/update_mint",
        data
      );

      if (result.data.result == "landscape") {
        this.outcome_img = "outcome_landscape.png";
        this.outcome_title = "A psychedelic memory of ancient landscapes.";
        this.outcome_description =
          "1 of the 4 NFTs needed to upgrade a Coral's background";
      } else {
        this.outcome_title = "You received a psychedelic memory of aqua's flow";

        if (result.data.result == "increased_125") {
          this.outcome_img = "outcome_125.png";
          this.outcome_description =
            "Increase staking yield of a Coral by 1.25x";
        } else if (result.data.result == "increased_150") {
          this.outcome_img = "outcome_150.png";
          this.outcome_description =
            "Increase staking yield of a Coral by 1.5x";
        } else {
          this.outcome_img = "outcome_200.png";
          this.outcome_description = "Increase staking yield of a Coral by 2x";
        }
      }

      $("#background-outcome").fadeIn(1000);
      $("#video video").hide();

      this.loading = false;

      cct_alert("Trip Memory minted successfully !");
    },

    wait_untill_wl_token_is_received: async function () {
      const token_address = "Hey31eCG5ZKSdgM2nFSdbvv5CNLYSWCBjdQVfvJairp1";

      // var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
      var tokenAccount = await findAssociatedTokenAddress(
        getWalletAddress(),
        token_address
      );

      var balance = await getTokenBalanceForTokenAndOwner(
        token_address,
        tokenAccount
      );

      if (balance > 0) {
        return true;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));

        return await this.wait_untill_wl_token_is_received();
      }
    },

    send_on_a_trip: async function () {
      this.loading = true;
      this.loading_text = "Embarking on ~Trip~, do not refresh or close page";

      let connection = await establishConnection();

      let signature;
      if (this.seamt_chosen == "token") {
        signature = await send_on_a_trip(
          getSolanaObject(),
          getWalletAddress(),
          this.seamt_chosen,
          this.aquapipe_ata,
          this.seamt_ata
        );
      } else {
        signature = await send_on_a_trip(
          getSolanaObject(),
          getWalletAddress(),
          this.seamt_chosen,
          this.aquapipe_ata
        );
      }

      if (signature === false) {
        this.loading = false;
        return;
      }

      // console.log('signature WL Token', signature);

      // we confirm signature for receving the token
      await connection.confirmTransaction(signature, "finalized");

      await this.wait_untill_wl_token_is_received();

      this.mint_one_token();
    },

    connexion_wallet_connect: async function (wallet_address) {
      this.wallet_address = wallet_address;

      this.check_if_aquapipe(wallet_address);
      this.check_if_seamt(wallet_address);
      this.check_if_seamt_token(wallet_address);
      this.check_if_wl_token(wallet_address);
    },

    confirm_transaction: async function (message, signature) {
      if (signature === false) {
        this.loading = false;
        return;
      }

      try {
        let connection = await establishConnection();

        await connection.confirmTransaction(signature, "finalized");

        this.display_message(message);
      } catch (e) {
        this.display_message(
          "Transaction did not confirm in 30 seconds, it is unknown if it succeeded or not, please check your wallet"
        );
      }
    },

    get_all_seamt_mints: async function () {
      var all_seamt_mints = await axios.get(
        BACKOFFICE_URL + "/cct/trip/get_all_seamt_mints"
      );

      this.all_seamt_mints = all_seamt_mints.data;
    },

    scroll_to_bottom: function () {
      $("html, body").animate(
        {
          scrollTop: $(document).height(),
        },
        100
      );
    },

    check_if_wl_token: async function (wallet_address) {
      const token_address = "Hey31eCG5ZKSdgM2nFSdbvv5CNLYSWCBjdQVfvJairp1";

      // var tokenAccount = await getTokenAccountForTokenAndOwner(token_address, wallet_address);
      var tokenAccount = await findAssociatedTokenAddress(
        wallet_address,
        token_address
      );

      var balance = await getTokenBalanceForTokenAndOwner(
        token_address,
        tokenAccount
      );

      this.wl_tokens = balance;
    },
  },

  mounted: function () {
    var $this = this;

    axios
      .get(BACKOFFICE_URL + "/cct/trip/get_water_condition_image")
      .then(function (result) {
        if (result.data.image == "Sea-MT-STABLE.jpg") {
          $this.seamt_image = "seamt_frame_stable.png";
        } else if (result.data.image == "Sea-MT-THREATENED.jpg") {
          $this.seamt_image = "seamt_frame_threatened.png";
        } else {
          $this.seamt_image = "seamt_frame_extreme.png";
        }
      });

    this.get_all_seamt_mints();

    var elem = $("[data-paroller-factor]");
    var setDirection = {
      bgVertical: function (elem, bgOffset) {
        return elem.css({
          "background-position": "center " + -bgOffset + "px",
        });
      },
      bgHorizontal: function (elem, bgOffset) {
        return elem.css({
          "background-position": -bgOffset + "px" + " center",
        });
      },
      vertical: function (elem, elemOffset) {
        return elem.css({
          "-webkit-transform": "translateY(" + elemOffset + "px)",
          "-moz-transform": "translateY(" + elemOffset + "px)",
          transform: "translateY(" + elemOffset + "px)",
        });
      },
      horizontal: function (elem, elemOffset) {
        return elem.css({
          "-webkit-transform": "translateX(" + elemOffset + "px)",
          "-moz-transform": "translateX(" + elemOffset + "px)",
          transform: "translateX(" + elemOffset + "px)",
        });
      },
    };

    $.fn.paroller = function (options_paroller) {
      var windowHeight = $(window).height();
      var documentHeight = $(document).height();

      // default options
      var options = $.extend(
        {
          factor: 0, // - to +
          type: "background", // foreground
          direction: "vertical", // horizontal
        },
        options_paroller
      );

      elem.each(function () {
        var $this = $(this);
        var offset = $this.offset().top;
        var height = $this.outerHeight();
        var dataFactor = $this.data("paroller-factor");
        var dataType = $this.data("paroller-type");
        var dataDirection = $this.data("paroller-direction");

        var factor = dataFactor ? dataFactor : options.factor;
        var type = dataType ? dataType : options.type;
        var direction = dataDirection ? dataDirection : options.direction;
        var bgOffset = Math.round(offset * factor);
        var transform = Math.round(
          (offset - windowHeight / 2 + height) * factor
        );

        if (type == "background") {
          if (direction == "vertical") {
            setDirection.bgVertical($this, bgOffset);
          } else if (direction == "horizontal") {
            setDirection.bgHorizontal($this, bgOffset);
          }
        } else if (type == "foreground") {
          if (direction == "vertical") {
            setDirection.vertical($this, transform);
          } else if (direction == "horizontal") {
            setDirection.horizontal($this, transform);
          }
        }

        $(window).on("scroll", function () {
          var scrolling = $(this).scrollTop();
          bgOffset = Math.round((offset - scrolling) * factor);
          transform = Math.round(
            (offset - windowHeight / 2 + height - scrolling) * factor
          );

          if (type == "background") {
            if (direction == "vertical") {
              setDirection.bgVertical($this, bgOffset);
            } else if (direction == "horizontal") {
              setDirection.bgHorizontal($this, bgOffset);
            }
          } else if (type == "foreground" && scrolling < documentHeight) {
            if (direction == "vertical") {
              setDirection.vertical($this, transform);
            } else if (direction == "horizontal") {
              setDirection.horizontal($this, transform);
            }
          }
        });
      });
    };

    // Carousel Init
    $(".paroller, [data-paroller-factor]").paroller({
      factor: 0.3, // +/-, if no other breakpoint factor is set this value is selected
      factorXs: 6, // factorXs, factorSm, factorMd, factorLg, factorXl
      factorSm: 6, // factorXs, factorSm, factorMd, factorLg, factorXl
      factorMd: 6, // factorXs, factorSm, factorMd, factorLg, factorXl
      factorLg: 6, // factorXs, factorSm, factorMd, factorLg, factorXl
      factorXl: 6, // factorXs, factorSm, factorMd, factorLg, factorXl
      type: "foreground", // background, foreground
      direction: "vertical", // vertical, horizontal
      transition: "translate 0.1s ease", // CSS transition, added on elements where type:'foreground'
    });
  },
};

const getProviderPhantom = () => {
  if ("solana" in window) {
    const provider = window.solana;

    if (provider.isPhantom) {
      return provider;
    }
  }

  window.open("https://phantom.app/", "_blank");
};

const isSolflareInstalled = window.solflare && window.solflare.isSolflare;

const getProviderSolflare = () => {
  if ("solflare" in window) {
    const provider = window.solflare;

    if (provider.isSolflare) {
      return provider;
    }
  }
  window.open("https://solflare.com", "_blank");
};
</script>
