import {PublicKey} from "@solana/web3.js";
import Wallet from "@project-serum/sol-wallet-adapter";
import bs58 from "bs58";

var slope_wallet = null;

var sollet_wallet = null;

export function connectWallet(wallet) { 

	
}

export async function signAndSendTransaction(wallet, connection, transaction) {
	
	var signature;
	
	if(wallet.type_wallet !== undefined && wallet.type_wallet === 'slope') {
		
		var msg, data; 
		
		try {
			
			const {msg, data} = await wallet.signTransaction(
				bs58.encode(transaction.serializeMessage())
			)
			
			if (msg === 'ok') {
				transaction.addSignature(
					new PublicKey(data.publicKey),
					bs58.decode(data.signature)
				);

				signature = await connection.sendRawTransaction(transaction.serialize());
			}
			
			return signature;
			
		}
		catch(e) {
			
			return false;
		}
		
		
	}
	else {
		
		try {
			
			// console.log("ok ici 2");
			var signedTransaction = await wallet.signTransaction(transaction);
			signature = await connection.sendRawTransaction(signedTransaction.serialize(), {skipPreflight: true});
		}
		catch(e) {
			
			console.log('error', e);
			
			return false;
		}
		
		// console.log('signedTransaction',signedTransaction);
		
	}
	
	return signature;
}

export async function signAndSendMultipleTransactions(wallet, connection, transactions) {
	
	console.log('debut');
	console.log('transactions', transactions);
	
    let txes = await wallet.signAllTransactions(transactions);
	
	console.log('apres signatures');
	
    let signatures = [];
	
    for (const tx of txes) {
		
		console.log('tx', tx);
		
		let signature = await connection.sendRawTransaction(tx.serialize());
		
		console.log('signature', signature);
		
        signatures.push(signature);
    }
	console.log('signatures', signatures);
	
    return signatures;
}


export function getWalletAddress(){

    if(window.solana && window.solana.publicKey) {
        if (window.solana.publicKey.toBase58() != undefined)
            return window.solana.publicKey.toBase58();
    }
	
	else if(window.solflare && window.solflare.publicKey) {
        if (window.solflare.publicKey.toString() != undefined)
            return window.solflare.publicKey.toString();
    }

    else if(sollet_wallet != null && sollet_wallet.connected) {
        if (sollet_wallet.publicKey.toString() != undefined)
            return sollet_wallet.publicKey.toString();
    }

    else if(slope_wallet != null && slope_wallet.publicKey) {
        if (slope_wallet.publicKey.toString() != undefined)
            return slope_wallet.publicKey.toString();
    }
	
    return false;

}

export function WalletLogout(){

    if(window.solana && window.solana.publicKey !== null)
        window.solana.disconnect();

    else if(window.solflare && window.solflare.publicKey !== null)
        window.solflare.disconnect();

    else if(sollet_wallet != null && sollet_wallet.connected)
        sollet_wallet.disconnect();

    else if(slope_wallet && slope_wallet.publicKey !== undefined) {
        slope_wallet.disconnect();
        delete slope_wallet.publicKey;
        delete slope_wallet.type_wallet;
    }
}

export async function connexion_with_slope(){
	
    try {

        slope_wallet = new window.Slope();

        const { msg, data } = await slope_wallet.connect();

        if (msg === 'ok'){

            slope_wallet.publicKey = new PublicKey(data.publicKey);
            slope_wallet.type_wallet = 'slope';
            return slope_wallet;
        }
        else
            return false;
    }
    catch (error) {
        return false;
    }
}

export async function connexion_with_sollet(){

    let providerUrl = 'https://www.sollet.io';

    sollet_wallet = new Wallet(providerUrl);

    await sollet_wallet.connect();

    return sollet_wallet;
}

export function getSolanaObject(){
    if(window.solana && window.solana.publicKey)
        return window.solana;
    else if(window.solflare && window.solflare.publicKey)
        return window.solflare;
    else if(sollet_wallet != null && sollet_wallet.connected)
        return sollet_wallet;
    else if(slope_wallet != null && slope_wallet.publicKey)
        return slope_wallet;
}



