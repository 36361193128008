import { Connection, PublicKey } from '@solana/web3.js';

// import {
//   getAllNft,
//   getNftToBeUpdated,
//   updateNftHolderByMint,
// } from '@/libs/tracker.js';



// import * as metadata from "@/metaplex/metadata";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

import { TOKEN_PROGRAM_ID } from "@solana/spl-token";


import { BinaryReader, BinaryWriter } from 'borsh';
import base58 from 'bs58';
import { StringPublicKey } from '@/metaplex/ids.js';

function extendBorsh() {
  (BinaryReader.prototype).readPubkey = function () {
    const reader = this;
    const array = reader.readFixedArray(32);
    return new PublicKey(array);
  };

  (BinaryWriter.prototype).writePubkey = function (value: PublicKey) {
    const writer = this;
    writer.writeFixedArray(value.toBuffer());
  };

  (BinaryReader.prototype).readPubkeyAsString = function () {
    const reader = this;
    const array = reader.readFixedArray(32);
    return base58.encode(array);
  };

  (BinaryWriter.prototype).writePubkeyAsString = function (
    value: StringPublicKey,
  ) {
    const writer = this;
    writer.writeFixedArray(base58.decode(value));
  };
}

extendBorsh();

var TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');


let connection: Connection;
let rpcUrl;

/**
 * Establish a connection to the cluster
 */
export async function establishConnection(network) {
	
	rpcUrl = "https://floral-still-borough.solana-mainnet.quiknode.pro/aa9d29376d76a7ba37a8dedce5dc29906c744873/";
	
	connection = new Connection(rpcUrl, 'confirmed');

	return connection;
}

/*
export async function getNftOwner(mint_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
	
		connection.getTokenLargestAccounts(new PublicKey(mint_address)).then(function(resultat) {
			
			resultat.value.forEach(function(owner) {
				
				if(owner.amount == 1) {
					
					connection.getParsedAccountInfo(owner.address).then(function(resultats_account) {
						
						var owner = resultats_account.value.data.parsed.info.owner;
						
						return resolve(owner);
					});
				}
			});
		});
	});
}

*/

export async function getTokenAccountForMintAddress(mint_address) {
	
	var accounts = await connection.getTokenLargestAccounts(new PublicKey(mint_address));
	
	var token_account = false;
	
	accounts.value.forEach(function(account) {
		
		if(account.amount == 1)
			token_account = account.address;
	});
	
	return token_account;
}

/*

export async function getNftOwnerAccount(mint_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
	
		connection.getTokenLargestAccounts(new PublicKey(mint_address)).then(function(resultat) {
			
			resultat.value.forEach(function(owner) {
				
				if(owner.amount == 1) {
					
					return resolve(owner.address);
				}
			});
		});
	});
}
*/

export async function getTokenBalanceForTokenAndOwner(token_address, owner) {
	
	await establishConnection('prod');
	
	var wallet = new PublicKey(owner);
	var token = new PublicKey(token_address);
	
	const account = await connection.getParsedAccountInfo(wallet);
	
	return account.value.data.parsed.info.tokenAmount.uiAmountString;
}

export async function getNftOwned(wallet_address) {
	
	await establishConnection();
	
	return new Promise(resolve => {
		
		connection.getParsedTokenAccountsByOwner(new PublicKey(wallet_address), {programId: TOKEN_PROGRAM_ID}).then(function(tokens){
			
			var nftList = [];
			
			tokens.value.forEach(function(token) {
				
				if(token.account.data.parsed.info.tokenAmount.amount == 1)
					nftList.push(token.account.data.parsed.info.mint);
			})
			
			return resolve(nftList);
		});
		
		
	});
}

/*
export async function getParsedTokenAccountsByOwner(owner) {
	
	await establishConnection();
	
	return new Promise(resolve => {
		
		connection.getParsedTokenAccountsByOwner(owner, {programId: TOKEN_PROGRAM_ID}).then(function(tokens){
			
			var nftList = [];
			
			tokens.value.forEach(function(token) {
				
				if(token.account.data.parsed.info.tokenAmount.amount == 1)
					nftList.push(token.account.data.parsed.info.mint);
			})
			
			return resolve(nftList);
		});
	});
}

// export function getNftTokenAccountOwners() {
//
// 	return new Promise(resolve => {
//
// 		var tokenAccountOwners = [];
//
// 		getNftToBeUpdated().then(function(mints) {
//
// 			var nftAccountToFetch = mints.length;
//
// 			mints.forEach(function(mint) {
//
// 				getNftOwnerAccount(mint.mint).then(function(tokenAccountOwner) {
//
// 					tokenAccountOwners.push(tokenAccountOwner);
//
// 					if(tokenAccountOwners.length == nftAccountToFetch)
// 						return resolve(tokenAccountOwners);
// 				});
// 			})
// 		});
// 	});
// }

export async function getOwnersFromTokenAccounts(accountsPublicKey) {
	
	var accountsPublicKeyArray = [];
	
	accountsPublicKey.forEach(function(key) {
		
		var publicKeyString = key.toString();

		accountsPublicKeyArray.push(new PublicKey(key.toString()));
	});
	
	return new Promise(resolve => {
	
		connection.getMultipleAccountsInfo(accountsPublicKeyArray).then(function(parsedAccounts) {
			
			var owners = [];
			
			parsedAccounts.forEach(function(account) {
				
				var parsedAccount = metadata.decodeMetadata(account.data);

				owners.push(account.owner.toString());
			})
			
			return resolve(owners);
		});
	});
}

export async function getNftByProgram(updateAuthority, symbol) {
	
	await establishConnection('prod');
	
	var config = {
		filters: [
			{
			memcmp: {
					offset: 1,
					bytes: updateAuthority,
				}
			}
		]
	};
	
	return new Promise(resolve => {
		
		var tokens = connection.getParsedProgramAccounts(TOKEN_METADATA_PROGRAM_ID, config).then(function(result) {
	
			var liste_mint = [];
			
			result.forEach(function(token, ordre) {
			
				var data = metadata.decodeMetadata(token.account.data);
				
				if(data.data.symbol != symbol)
					return;
				
				// liste_mint.push({mint: data.mint, name: data.data.name, picture: data.data.uri});
				liste_mint.push(data);
			});
			
			return resolve(liste_mint);
		});
		
		
	});
}

*/

// le principe est là, mais il faut tout faire
export async function getNftMetadata(mint) {
	
	console.log('debut');
	
	mint = new PublicKey(mint);
	
	await establishConnection('prod');
	
	console.log('getParsedAccountInfo');
	
	// console.log(Metadata);
	
	const [pda_metadata, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint.toBuffer(), 
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	
	let tokenmetaPubkey = await Metadata.fromAccountAddress(connection, pda_metadata);
	
	console.log(tokenmetaPubkey);
	
	var uri = tokenmetaPubkey.data.uri.replaceAll('\u0000', '');
	
	
}



export async function getTokenAccountForTokenAndOwner(token_address, owner) {
	
	await establishConnection('prod-solana');
	
	var config = {
		filters: [
			{
				"dataSize": 165
			},
			{
			memcmp: {
					offset: 0,
					bytes: token_address,
				}
			}
		]
	};
	
	var tokens = await connection.getProgramAccounts(new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"), config);
	
	return new Promise(resolve => {
		tokens.forEach(async function(token) {
			
			var owner_tmp = await getTokenAccountOwner(token.pubkey);
			
			if(owner_tmp == owner) {
				
				return resolve(token.pubkey.toString());
			}
		});
	});
	
	
	
	
}

/*
export async function getHoldersForToken(token_address) {
	
	await establishConnection('prod');
	
	var config = {
		filters: [
			{
				"dataSize": 165
			},
			{
			memcmp: {
					offset: 0,
					bytes: token_address,
				}
			}
		]
	};
	
	return new Promise(resolve => {
		
		var tokens = connection.getProgramAccounts(new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"), config).then(async function(result) {
	
			var liste_mint = [];
			
			// console.log(result);
			var json = [];
			
			await result.forEach(async function(token) {
				
				
				var tokensBalance = await getTokenAccountBalance(token.pubkey);
				var owner = await getTokenAccountOwner(token.pubkey);
				
				if(owner !== undefined) {
					
					var accountInfo = {
						
						public_key: token.pubkey.toString(),
						tokens: tokensBalance,
						owner: owner.toString()
					};
					
					if(json[accountInfo.owner] == undefined)
						console.log("spl-token transfer 8BKVqwa9kGFkzFqJRRfCDUtKJNqvg991t85MNdpXYMhx "+accountInfo.tokens+" "+accountInfo.owner+" --fund-recipient");
					
					// console.log("json["+accountInfo.owner+"] = "+accountInfo.tokens+";");
					
					liste_mint.push(accountInfo);
				}
			});
			
			return resolve(liste_mint);
		});
		
		
	});
}

export async function getTokenAccountBalance(tokenAccount){
	
	return new Promise(resolve => {

		connection.getTokenAccountBalance(tokenAccount).then(function(resultat) {
			
			return resolve(resultat.value.uiAmount);

		});
	});
}
*/

export async function getTokenAccountOwner(account){
	
	return new Promise(resolve => {

		connection.getParsedAccountInfo(account).then(function(resultat) {
			
			// console.log('resultat getAccountInfo', resultat);
			
			return resolve(resultat.value.data.parsed.info.owner);

		});
	});
}

/*

export async function getBalance(mint_address){

	await establishConnection();

	return new Promise(resolve => {

		connection.getBalance(new PublicKey(mint_address)).then(function(resultat) {

			return resolve(resultat / 1000000000);

		});
	});

}
*/

export async function findAssociatedTokenAddress(wallet_address, mint_address) {

	const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
		'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
		);
		
		mint_address = new PublicKey(mint_address);
		wallet_address = new PublicKey(wallet_address);
	
	return (await PublicKey.findProgramAddress(
        [
            wallet_address.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            mint_address.toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    ))[0];
}

/*
export async function fetchAccount(publicKey, program) {
	
	let account = await program.account.escrowAccount.fetch(publicKey);

	return account;
}

export async function fetchAccountOffer(publicKey, program) {

	let account = await program.account.escrowAccountOffer.fetch(publicKey);

	return account;
}


*/